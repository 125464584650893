import React from "react";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import SubNavbar from "./SubNavbar/SubNavbar";

const Layout: React.FC<any> = (props) => {
  return (
    <div>
      <Navbar />
      <SubNavbar />

      <div>{props.children}</div>

      <Footer />
    </div>
  );
};

export default Layout;
