import React from "react";
import CookieConsent from "react-cookie-consent";

const CustomCookieConsent = () => {
  return (
    // @ts-ignore
    <CookieConsent
      location="bottom"
      buttonText="I accept cookies"
      cookieName="whereishot"
      buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
      style={{ background: "#2b373b" }}
      expires={150}
      enableDeclineButton
      declineButtonStyle={{ color: "#fefefe", fontSize: "14px" }}
      declineButtonText="I refuse cookies"
      flipButtons
    >
      <span style={{ fontSize: "16px" }}>
        This website uses cookies to enhance the user experience.
      </span>
    </CookieConsent>
  );
};

export default CustomCookieConsent;
