import React from "react";
import { Nav } from "react-bootstrap";
import { useRouter } from "next/router";
import { CustomNavLink, CustomSubNavbar } from "./SubNavbar.styled";
import useTranslation from "next-translate/useTranslation";

const SubNavbar = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const onHandleActiveNav = (url: string) => {
    if (!url) {
      return false;
    }

    const isActive = router.pathname === url ? true : false;

    return isActive;
  };

  return (
    <CustomSubNavbar bg="light" expand="lg">
      <div className="container">
        <CustomSubNavbar.Toggle aria-controls="basic-navbar-nav" />
        <CustomSubNavbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <Nav>
            <CustomNavLink href="/" active={onHandleActiveNav("/")}>
              {t("common:navbar.all_deals")}
            </CustomNavLink>
            <CustomNavLink
              href="/home-and-garden"
              active={onHandleActiveNav("/home-and-garden")}
            >
              {t("common:navbar.home_and_garden")}
            </CustomNavLink>
            <CustomNavLink
              href="/electronics"
              active={onHandleActiveNav("/electronics")}
            >
              {t("common:navbar.electronics")}
            </CustomNavLink>
            <CustomNavLink
              href="/computers"
              active={onHandleActiveNav("/computers")}
            >
              {t("common:navbar.computers")}
            </CustomNavLink>
            <CustomNavLink
              href="/automotives"
              active={onHandleActiveNav("/automotives")}
            >
              {t("common:navbar.automotives")}
            </CustomNavLink>
            <CustomNavLink
              href="/apparels"
              active={onHandleActiveNav("/apparels")}
            >
              {t("common:navbar.apparels")}
            </CustomNavLink>
            <CustomNavLink
              href="/furnitures"
              active={onHandleActiveNav("/furnitures")}
            >
              {t("common:navbar.furnitures")}
            </CustomNavLink>
            <CustomNavLink href="/pets" active={onHandleActiveNav("/pets")}>
              {t("common:navbar.pets")}
            </CustomNavLink>
            <CustomNavLink href="/foods" active={onHandleActiveNav("/foods")}>
              {t("common:navbar.foods")}
            </CustomNavLink>
            <CustomNavLink href="/others" active={onHandleActiveNav("/others")}>
              {t("common:navbar.others")}
            </CustomNavLink>
            <CustomNavLink
              href="/coupons"
              active={onHandleActiveNav("/coupons")}
            >
              {t("common:navbar.coupons")}
            </CustomNavLink>
            <CustomNavLink href="/posts" active={onHandleActiveNav("/posts")}>
              {t("common:navbar.posts")}
            </CustomNavLink>
          </Nav>
        </CustomSubNavbar.Collapse>
      </div>
    </CustomSubNavbar>
  );
};

export default SubNavbar;
