
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from "react";
import Layout from "../components/Layout/Layout";
import GlobalStyled from "../styles/GlobalStyled";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import CustomCookieConsent from "../components/CustomCookieConsent/CustomCookieConsent";

function MyApp({ Component, pageProps }: any) {
  return (
    <div>
      <GlobalStyled />
      <CustomCookieConsent />
      <Layout>
        <ScrollToTop />
        <Component {...pageProps} />
      </Layout>
    </div>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  