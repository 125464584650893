import styled from "styled-components";

export const NavbarContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;

  .custom_input {
    margin: 0px 0px 0px 40px;
    padding-left: 25px;
    border-top-left-radius: 1.6rem;
    border-bottom-left-radius: 1.6rem;
    border-right: none;

    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }

    @media screen and (max-width: 492px) {
      margin: 0px;
    }
  }

  .search_button {
    display: flex;
    align-items: center;
    padding: 5px 15px 5px 15px;
    border: 1px solid #ced4da;
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;

    &:hover {
      cursor: pointer;
      background-color: #2472aa;
      /* transition: all 0.4s; */

      .search-icon {
        color: #fefefe;
      }
    }
  }

  .search-icon {
    height: 25px;
    color: #373940;
  }

  .custom_btn {
    width: 75px;
    width: 75px;
    background-color: #2472aa;
    border-radius: 25px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const Logo = styled.p`
  font-weight: 600;
  font-size: 2rem;
  text-align: center;

  span {
    color: red;
  }

  a {
    color: black !important;
    text-decoration: none;
  }

  @media screen and (max-width: 492px) {
    display: none;
  }
`;

export const MobileLogo = styled.p`
  font-weight: 600;
  font-size: 2rem;
  display: none;

  span {
    color: red;
  }

  a {
    color: black !important;
    text-decoration: none;
  }

  @media screen and (max-width: 492px) {
    display: block;
  }
`;
