import styled from "styled-components";

export const FooterContainer = styled.div`
  background-color: #f1f4f6;
  padding-top: 30px;
  padding-bottom: 30px;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  .footer_title {
    font-size: 13px;
    color: #232f3e;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .footer_text {
    font-size: 12px;
    color: #232f3e;
    font-weight: normal;
  }

  .footer_item {
    font-size: 13px;
    color: #232f3e;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
