import { createGlobalStyle } from "styled-components";

const GlobalStyled = createGlobalStyle`
  body {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
  }

  .container {
    @media screen and (min-width: 1400px) {
      max-width: 1180px;
    }
  }
`;

export default GlobalStyled;
