import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Logo, MobileLogo, NavbarContainer } from "./Navbar.styled";
import { useRouter } from "next/router";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const Navbar = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const onHandleSearch = (e: any) => {
    e.preventDefault();

    router.push({
      pathname: "/products",
      query: {
        search,
      },
    });
  };

  const onHandleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      router.push({
        pathname: "/products",
        query: {
          search,
        },
      });
    }
  };

  return (
    <NavbarContainer>
      <div className="container mb-3 d-flex justify-content-center">
        <MobileLogo>
          <Link href="/">
            <a>
              Where&apos;s<span>Hot</span>
            </a>
          </Link>
        </MobileLogo>
      </div>

      <div className="container d-flex">
        <Logo>
          <Link href="/">
            <a>
              Where&apos;s<span>Hot</span>
            </a>
          </Link>
        </Logo>

        <input
          type="text"
          name="search_product"
          className="form-control custom_input"
          placeholder={t("common:subnavbar.search.placeholder")}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => onHandleKeyPress(e)}
          value={search}
        />
        <div className="search_button" onClick={(e) => onHandleSearch(e)}>
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
