import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";

export const CustomSubNavbar = styled(Navbar)`
  background-color: #f1f4f6 !important;

  .navbar-nav {
    flex-wrap: wrap;
  }
`;

export const CustomNavLink = styled(Nav.Link)`
  &.active {
    color: #2472aa !important;
  }
`;
