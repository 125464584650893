import Link from "next/link";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FooterContainer } from "./Footer.styled";
import useTranslation from "next-translate/useTranslation";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <div className="container">
        <Row className="justify-content-center">
          <Col md={2}>
            <h6 className="footer_title">
              {t("common:footer.company_info.title")}
            </h6>

            <ul>
              <li>
                <Link href="/about">
                  <a className="footer_item">
                    {t("common:footer.company_info.about_us")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/policy-and-privacy">
                  <a className="footer_item">
                    {t("common:footer.company_info.policy_and_privacy")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/contact-us">
                  <a className="footer_item">
                    {t("common:footer.company_info.contact_us")}
                  </a>
                </Link>
              </li>
            </ul>
          </Col>

          <Col md={2}>
            <h6 className="footer_title">
              {t("common:footer.community.title")}
            </h6>

            <ul>
              <li>
                <Link href="https://www.facebook.com/WhereisHot-105881472201115">
                  <a className="footer_item" target="_blank">
                    {t("common:footer.community.facebook")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://twitter.com/whereishot1">
                  <a className="footer_item" target="_blank">
                    {t("common:footer.community.twitter")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://www.youtube.com/channel/UCG_mPmqAGCgWecFrLTpQY1g">
                  <a className="footer_item" target="_blank">
                    {t("common:footer.community.youtube")}
                  </a>
                </Link>
              </li>
            </ul>
          </Col>

          <Col md={2}>
            <h6 className="footer_title">
              {t("common:footer.boring_stuff.title")}
            </h6>

            <ul>
              <li>
                <Link href="/terms-and-conditions">
                  <a className="footer_item">
                    {t("common:footer.boring_stuff.terms_and_conditions")}
                  </a>
                </Link>
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h2 className="footer_title">
              {t("common:footer.affiliate_program.title")}
            </h2>
            <h2 className="footer_text">
              {t("common:footer.affiliate_program.description")}
            </h2>
          </Col>
        </Row>
      </div>
    </FooterContainer>
  );
};

export default Footer;
